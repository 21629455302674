import React, { useState } from "react";
import { useAppContext } from "../../context/appContext";
import { fetchLists } from "../../api/services";

function ListStudents() {
  const [students, setStudents] = useState([]);
  const { user: logged } = useAppContext();

  const user = JSON.parse(logged);

  const updateState = ({ data }) => {
    setStudents(data);
  };

  useState(() => {
    if (user) {
      fetchLists({ role: "student" }, "/members", updateState);
    }
  }, []);
  return (
    <div className="flex flex-col w-full">
      <div className="overflow-x-auto ">
        <div className="align-middle inline-block min-w-full ">
          <div className="shadow overflow-hidden border-b border-gray-200 rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Student Email
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Location
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Created Date
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {students?.map((item, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">{item?.email}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap cursor-pointer ">
                      <div className="text-sm font-medium text-gray-900 hover:text-blue-500">
                        {item?.location}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {new Date(item?.created_at).toLocaleDateString()}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListStudents;
