import React from "react";
import { useAppContext } from "../context/appContext";

function MemberProfile() {
  const { user: logged } = useAppContext();
  const specificMember = JSON.parse(logged);
  return (
    <div className="flex flex-col">
      <div className="flex flex-col justify-between">
        <div className="grid sm:grid-cols-2 grid-cols-1 gap-4">
          <div>
            <p className="text-gray-700 font-medium">Email:</p>
            <p>{specificMember?.email}</p>
          </div>
          <div>
            <p className="text-gray-700 font-medium">Account ID:</p>
            <p>{specificMember?.id}</p>
          </div>
          <div>
            <p className="text-gray-700 font-medium">Member Role:</p>
            <p>{specificMember?.role}</p>
          </div>
          <div>
            <p className="text-gray-700 font-medium">Location:</p>
            <p>{specificMember?.location}</p>
          </div>
          <div>
            <p className="text-gray-700 font-medium">Created Data:</p>
            <p>{new Date(specificMember?.created_at).toLocaleDateString()}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MemberProfile;
