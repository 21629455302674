import React, { useEffect, useState } from "react";
import { useAppContext } from "../../context/appContext";
import { fetchLists, applyJob } from "../../api/services";
import { axiosClient } from "../../api/config";

function Jobs() {
  const [jobs, setJObs] = useState([]);
  const { user: logged } = useAppContext();

  const user = JSON.parse(logged);

  const updateState = async ({ data: allJobs }) => {

    try {
      const { data } = await axiosClient.post("/applied_jobs", {
        student_id: user.id,
      });
  
      const appliedJobs = data.jobs?.map((item) => item.job.id);
  
      const finalJobs = allJobs?.filter(
        (item) => !appliedJobs.includes(item.id) && item
      );
      setJObs(finalJobs);
    } catch (error) {
      setJObs(allJobs)
    }
  };

  

  useEffect(() => {
    if (user) {
      fetchLists({}, "/jobs", updateState);
    }
  }, []);

  return (
    <div className="flex flex-col w-full">
      <div className="overflow-x-auto ">
        <div className="align-middle inline-block min-w-full ">
          <div className="shadow overflow-hidden border-b border-gray-200 rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Job Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Required Experience
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Posted Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {jobs?.map((jobDrive, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {jobDrive?.job_name}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap cursor-pointer ">
                      <div className="text-sm font-medium text-gray-900 hover:text-blue-500">
                        {jobDrive?.description}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {jobDrive?.required_experience} year
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {new Date(jobDrive?.created_at).toLocaleDateString()}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <a
                        className="text-sm text-blue-500 cursor-pointer"
                        onClick={() =>
                          applyJob({
                            job: jobDrive?.id,
                            member: user.id,
                            name: jobDrive?.job_name,
                          })
                        }
                        target="__blank"
                      >
                        Apply
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Jobs;
