import React, { useState } from "react";
import Logo from "../components/Logo";
import { Link } from "react-router-dom";
import {getBlogPosts} from "../api/services"
function BlogPosts() {
  const [blogs, setBlogs] = useState([]);

  useState(() => {
    getBlogPosts().then((data) => setBlogs(data));
  }, []);

  console.log(blogs);
  return (
    <div className="m-auto">
      <Logo />
      <div className="px-6 py-2 max-w-6xl m-auto h-screen">
        <h1 class="text-3xl mt-40 font-semibold text-gray-800 capitalize lg:text-4xl dark:text-white">
          Posts
        </h1>
        <div className="w-full ">
          <section class="bg-white dark:bg-gray-900">
            <div class="container px-6 py-10 ">
              <div class="grid grid-cols-2 gap-8 mt-8 md:mt-16 md:grid-cols-2">
                {blogs.map((blog) => (
                  <div class="lg:flex">
                    <img
                      class="object-cover w-full h-56 rounded-lg lg:w-64"
                      src={
                        blog?.image ??
                        "https://images.unsplash.com/photo-1515378960530-7c0da6231fb1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                      }
                      alt=""
                    />

                    <div class="flex flex-col justify-between py-6 lg:mx-6">
                      
                      <Link
                        to="/post"
                        state={blog}
                        class="text-xl font-semibold text-gray-800 hover:underline dark:text-white "
                      >
                        {blog.title.rendered}
                      </Link>

                      <span class="text-sm text-gray-500 dark:text-gray-300">
                        On: {new Date(blog.modified).toDateString()}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default BlogPosts;
