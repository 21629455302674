import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Error, Landing, ProtectedRoute } from "./pages";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import SharedLayout from "./components/Dashboard/SharedLayout";

//new
import Login from "./layouts/login";
import Student from "./layouts/Student";
import MemberProfile from "./layouts/profile";
import AdminHome from "./layouts/Admin";
import Register from "./layouts/register";
import DeiHome from "./layouts/Dei";
import PostJob from "./layouts/Dei/postjobs";
import AppliedJobOrRequest from "./layouts/applied_job";
import Jobs from "./layouts/Student/jobs";
import ListStudents from "./layouts/Admin/students";
import ListDeis from "./layouts/Admin/deis";
import Chart from "./layouts/Admin/chart";
import Chat from "./layouts/chat";
import ChatRoom from "./layouts/chatRoom";
import CreateRoom from "./layouts/createRoom";
import MemberChart from "./layouts/Admin/memberChart";
import BlogPosts from "./layouts/BlogPosts";
import SinglePost from "./layouts/SinglePost";
import { ForgetPwd } from "./api/services";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <SharedLayout />
            </ProtectedRoute>
          }
        >
          <Route path="/student" element={<Student />} />
          <Route path="/profile" element={<MemberProfile />} />
          <Route path="/admin" element={<AdminHome />} />
          <Route path="/dei" element={<DeiHome />} />
          <Route path="/create_job" element={<PostJob />} />
          <Route path="/appliedOrRequest" element={<AppliedJobOrRequest />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/students" element={<ListStudents />} />
          <Route path="/deis" element={<ListDeis />} />
          <Route path="/chart" element={<Chart />} />
          <Route path="/chat/:id" element={<Chat />} />
          <Route path="/chat_room" element={<ChatRoom />} />
          <Route path="/create_room" element={<CreateRoom />} />
          <Route path="/members" element={<MemberChart />} />
        </Route>
        <Route path="/register" element={<Register />} />
        <Route path="/ForgetPassword" element={<ForgetPwd />} />
        <Route path="/login" element={<Login />} />
        <Route path="/landing" element={<Landing />} />
        <Route path="/blog" element={<BlogPosts />} />
        <Route path="/post" element={<SinglePost />} />

        <Route path="*" element={<Error />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </Router>
  );
}

export default App;
