import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppContext } from "../context/appContext";
import { fetchLists } from "../api/services";
import { axiosClient } from "../api/config";

function Chat() {
  const { user: logged } = useAppContext();

  const user = JSON.parse(logged);

  const { id } = useParams();

  const [messages, setMessage] = useState([]);
  const [msg, setMsg] = useState("");

  const updateState = ({ data }) => {
    if (data?.length) {
      setMessage(data);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchLists({ room_id: id }, "/get_chats", updateState);
    }, 3000);

    return () => clearInterval(interval);
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    await axiosClient.post("/add_chat", {
      room: id,
      member: user.id,
      message: msg,
    });
    setMsg("");
  };

  return (
    <div class="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 h-full p-4">
      <div class="flex flex-col h-full overflow-x-auto mb-4">
        <div class="flex flex-col h-full">
          <div class="grid grid-cols-12 gap-y-2 h-[80%]">
            {messages?.map((message) => {
              const match = user.id === message.member.id;

              return (
                <div
                  class={
                    match
                      ? "col-start-1 col-end-8 p-3 rounded-lg"
                      : "col-start-6 col-end-13 p-3 rounded-lg"
                  }
                >
                  <div
                    class={
                      match
                        ? "flex flex-row items-center"
                        : "flex items-center justify-start flex-row-reverse"
                    }
                  >
                    <div class="flex  items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0">
                      {`${message.member.email}`[0]}
                    </div>
                    <div
                      class={`relative ${
                        match ? "ml-3" : "mr-3"
                      } text-sm bg-white py-2 px-4 shadow rounded-xl`}
                    >
                      <div>{message.message}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <form
        onSubmit={handleSubmit}
        class="flex flex-row items-center h-16 rounded-xl bg-white w-full px-4"
      >
        <div class="flex-grow ml-4">
          <div class="relative w-full">
            <input
              type="text"
              name="msg"
              value={msg}
              onChange={(e) => setMsg(e.target.value)}
              class="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10"
            />
          </div>
        </div>
        <div class="ml-4">
          <button
            type="submit"
            class="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1 flex-shrink-0"
          >
            <span>Send</span>
            <span class="ml-2">
              <svg
                class="w-4 h-4 transform rotate-45 -mt-px"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                ></path>
              </svg>
            </span>
          </button>
        </div>
      </form>
    </div>
  );
}

export default Chat;
