import React, { useEffect, useState } from "react";
import { useAppContext } from "../../context/appContext";
import { fetchLists } from "../../api/services";

import "chart.js/auto";
import { Chart as Base } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
} from "chart.js";

ChartJS.register(LineController, LineElement, PointElement, LinearScale, Title);

function Chart() {
  const [jobs, setJObs] = useState([]);
  const { user: logged } = useAppContext();

  const user = JSON.parse(logged);

  const updateState = ({ data }) => {
    setJObs(data.jobs);
  };

  useEffect(() => {
    if (user) {
      fetchLists({}, "/applied_jobs", updateState);
    }
  }, []);

  const lists = jobs?.map((item) => {
    return item.dei;
  });

  const companies = {};

  lists.map((item) => {
    companies[item.email] =
      typeof companies[item.email] === "number" ? companies[item.email] + 1 : 1;
  });

  const datas = Object.values(companies);

  const labels = jobs?.map((item) => {
    return item.dei.email;
  });

  const data = {
    labels: [...new Set(labels)],
    datasets: [
      {
        label: "Applied Jobs",
        data: datas,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(201, 203, 207, 0.2)",
        ],
        borderColor: [
          "rgb(255, 99, 132)",
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(201, 203, 207)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="chart-container">
      <h2 style={{ textAlign: "center" }}>Bar Chart</h2>
      <Base
        type="bar"
        data={data}
        options={{
          plugins: {
            title: {
              display: true,
              text: "Company Got Job Requests",
            },
          },
        }}
      />
    </div>
  );
}

export default Chart;
