export const REGISTER_USER_BEGIN = "REGISTER_USER_BEGIN";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";

export const LOGIN_USER_BEGIN = "LOGIN_USER_BEGIN";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";

export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD_BEGIN = "FORGOT_PASSWORD_BEGIN";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const CLEAR_ALERT = "CLEAR_ALERT";
export const DISPLAY_ALERT = "DISPLAY_ALERT";
export const HANDLE_CHANGE = "HANDLE_CHANGE";
export const UPDATE_STATE_VALUES = "UPDATE_STATE_VALUES";
export const SET_IMAGE_URL = "SET_IMAGE_URL";
export const SET_PDF_URL = "SET_PDF_URL";
export const SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS";




export const GET_PROFILE_BEGIN = "GET_PROFILE_BEGIN";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_ERROR = "GET_PROFILE_ERROR";
export const UPDATE_PROFILE_BEGIN = "UPDATE_PROFILE_BEGIN";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";
export const CREATE_ADMIN_BEGIN = "CREATE_ADMIN_BEGIN";
export const CREATE_ADMIN_SUCCESS = "CREATE_ADMIN_SUCCESS";
export const CREATE_ADMIN_ERROR = "CREATE_ADMIN_ERROR";
export const CREATE_COMPANY_BEGIN = "CREATE_COMPANY_BEGIN";
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS";
export const CREATE_COMPANY_ERROR = "CREATE_COMPANY_ERROR";
export const GET_STUDENTSBYADMIN_BEGIN = "GET_STUDENTSBYADMIN_BEGIN";
export const GET_STUDENTSBYADMIN_SUCCESS = "GET_STUDENTSBYADMIN_SUCCESS";
export const GET_STUDENTSBYIDBYADMIN_BEGIN = "GET_STUDENTSBYIDBYADMIN_BEGIN";
export const GET_STUDENTSBYIDBYADMIN_SUCCESS = "GET_STUDENTSBYIDBYADMIN_SUCCESS";

export const GET_COMPANIESBYADMIN_BEGIN = "GET_COMPANIESBYADMIN_BEGIN";
export const GET_COMPANIESBYADMIN_SUCCESS = "GET_COMPANIESBYADMIN_SUCCESS";
export const GET_COMPANIESBYIDBYADMIN_BEGIN = "GET_COMPANIESBYIDBYADMIN_BEGIN";
export const GET_COMPANIESBYIDBYADMIN_SUCCESS = "GET_COMPANIESBYIDBYADMIN_SUCCESS";

export const GET_JOBSBYADMIN_BEGIN = "GET_JOBSBYADMIN_BEGIN";
export const GET_JOBSBYADMIN_SUCCESS = "GET_JOBSBYADMIN_SUCCESS";
export const GET_JOBSBYIDBYADMIN_BEGIN = "GET_JOBSBYIDBYADMIN_BEGIN";
export const GET_JOBSBYIDBYADMIN_SUCCESS = "GET_JOBSBYIDBYADMIN_SUCCESS";

export const VERIFY_JOB_BEGIN = "VERIFY_JOB_BEGIN";
export const VERIFY_JOB_SUCCESS = "VERIFY_JOB_SUCCESS";
export const VERIFY_JOB_ERROR = "VERIFY_JOB_ERROR";
export const VERIFY_STUDENT_BEGIN = "VERIFY_STUDENT_BEGIN";
export const VERIFY_STUDENT_SUCCESS = "VERIFY_STUDENT_SUCCESS";
export const VERIFY_STUDENT_ERROR = "VERIFY_STUDENT_ERROR";

export const SEND_NOTIFICATION_BEGIN = "SEND_NOTIFICATION_BEGIN";
export const SEND_NOTIFICATION_SUCCESS = "SEND_NOTIFICATION_SUCCESS";
export const SEND_NOTIFICATION_ERROR = "SEND_NOTIFICATION_ERROR";
export const SEND_MESSAGEONMAIL_BEGIN = "SEND_MESSAGEONMAIL_BEGIN";
export const SEND_MESSAGEONMAIL_SUCCESS = "SEND_MESSAGEONMAIL_SUCCESS";
export const SEND_MESSAGEONMAIL_ERROR = "SEND_MESSAGEONMAIL_ERROR";
export const GET_STATSBYADMIN_BEGIN = "GET_STATSBYADMIN_BEGIN";
export const GET_STATSBYADMIN_SUCCESS = "GET_STATSBYADMIN_SUCCESS";



//STUDENT
export const GET_PROFILE_BASIC_BEGIN = "GET_PROFILE_BASIC_BEGIN";
export const GET_PROFILE_BASIC_SUCCESS = "GET_PROFILE_BASIC_SUCCESS";
export const GET_PROFILE_BASIC_ERROR = "GET_PROFILE_BASIC_ERROR";
export const GET_PROFILE_PERSONAL_BEGIN = "GET_PROFILE_PERSONAL_BEGIN";
export const GET_PROFILE_PERSONAL_SUCCESS = "GET_PROFILE_PERSONAL_SUCCESS";
export const GET_PROFILE_PERSONAL_ERROR = "GET_PROFILE_PERSONAL_ERROR";
export const GET_PROFILE_ACADEMIC_BEGIN = "GET_PROFILE_ACADEMIC_BEGIN";
export const GET_PROFILE_ACADEMIC_SUCCESS = "GET_PROFILE_ACADEMIC_SUCCESS";
export const GET_PROFILE_ACADEMIC_ERROR = "GET_PROFILE_ACADEMIC_ERROR";
export const GET_PROFILE_PROFESSIONAL_BEGIN = "GET_PROFILE_PROFESSIONAL_BEGIN";
export const GET_PROFILE_PROFESSIONAL_SUCCESS = "GET_PROFILE_PROFESSIONAL_SUCCESS";
export const GET_PROFILE_PROFESSIONAL_ERROR = "GET_PROFILE_PROFESSIONAL_ERROR";
export const GET_PROFILE_DOCUMENT_BEGIN = "GET_PROFILE_DOCUMENT_BEGIN";
export const GET_PROFILE_DOCUMENT_SUCCESS = "GET_PROFILE_DOCUMENT_SUCCESS";
export const GET_PROFILE_DOCUMENT_ERROR = "GET_PROFILE_DOCUMENT_ERROR";
export const UPDATE_PROFILE_BASIC_BEGIN = "UPDATE_PROFILE_BASIC_BEGIN";
export const UPDATE_PROFILE_BASIC_SUCCESS = "UPDATE_PROFILE_BASIC_SUCCESS";
export const UPDATE_PROFILE_BASIC_ERROR = "UPDATE_PROFILE_BASIC_ERROR";
export const UPDATE_PROFILE_PERSONAL_BEGIN = "UPDATE_PROFILE_PERSONAL_BEGIN";
export const UPDATE_PROFILE_PERSONAL_SUCCESS = "UPDATE_PROFILE_PERSONAL_SUCCESS";
export const UPDATE_PROFILE_PERSONAL_ERROR = "UPDATE_PROFILE_PERSONAL_ERROR";
export const UPDATE_PROFILE_ACADEMIC_BEGIN = "UPDATE_PROFILE_ACADEMIC_BEGIN";
export const UPDATE_PROFILE_ACADEMIC_SUCCESS = "UPDATE_PROFILE_ACADEMIC_SUCCESS";
export const UPDATE_PROFILE_ACADEMIC_ERROR = "UPDATE_PROFILE_ACADEMIC_ERROR";
export const UPDATE_PROFILE_PROFESSIONAL_BEGIN = "UPDATE_PROFILE_PROFESSIONAL_BEGIN";
export const UPDATE_PROFILE_PROFESSIONAL_SUCCESS = "UPDATE_PROFILE_PROFESSIONAL_SUCCESS";
export const UPDATE_PROFILE_PROFESSIONAL_ERROR = "UPDATE_PROFILE_PROFESSIONAL_ERROR";
export const UPDATE_PROFILE_DOCUMENT_BEGIN = "UPDATE_PROFILE_DOCUMENT_BEGIN";
export const UPDATE_PROFILE_DOCUMENT_SUCCESS = "UPDATE_PROFILE_DOCUMENT_SUCCESS";
export const UPDATE_PROFILE_DOCUMENT_ERROR = "UPDATE_PROFILE_DOCUMENT_ERROR";


export const GET_STUDENTSBYIDBYSTUDENT_BEGIN = "GET_STUDENTSBYIDBYSTUDENT_BEGIN";
export const GET_STUDENTSBYIDBYSTUDENT_SUCCESS = "GET_STUDENTSBYIDBYSTUDENT_SUCCESS";

export const GET_COMPANIESBYSTUDENT_BEGIN = "GET_COMPANIESBYSTUDENT_BEGIN";
export const GET_COMPANIESBYSTUDENT_SUCCESS = "GET_COMPANIESBYSTUDENT_SUCCESS";
export const GET_COMPANIESBYIDBYSTUDENT_BEGIN = "GET_COMPANIESBYIDBYSTUDENT_BEGIN";
export const GET_COMPANIESBYIDBYSTUDENT_SUCCESS = "GET_COMPANIESBYIDBYSTUDENT_SUCCESS";

export const GET_JOBSBYSTUDENT_BEGIN = "GET_JOBSBYSTUDENT_BEGIN";
export const GET_JOBSBYSTUDENT_SUCCESS = "GET_JOBSBYSTUDENT_SUCCESS";
export const GET_JOBSBYIDBYSTUDENT_BEGIN = "GET_JOBSBYIDBYSTUDENT_BEGIN";
export const GET_JOBSBYIDBYSTUDENT_SUCCESS = "GET_JOBSBYIDBYSTUDENT_SUCCESS";

export const APPLY_JOBBYSTUDENT_BEGIN = "APPLY_JOBBYSTUDENT_BEGIN";
export const APPLY_JOBBYSTUDENT_SUCCESS = "APPLY_JOBBYSTUDENT_SUCCESS";
export const APPLY_JOBBYSTUDENT_ERROR = "APPLY_JOBBYSTUDENT_ERROR";


export const GET_STATSBYSTUDENT_BEGIN   = "GET_STATSBYSTUDENT_BEGIN";
export const GET_STATSBYSTUDENT_SUCCESS = "GET_STATSBYSTUDENT_SUCCESS";
export const GET_STATSBYSTUDENT_ERROR   = "GET_STATSBYSTUDENT_ERROR";

//COMPANY

export const GET_COMPANIESBYIDBYCOMPANY_BEGIN = "GET_COMPANIESBYIDBYCOMPANY_BEGIN";
export const GET_COMPANIESBYIDBYCOMPANY_SUCCESS = "GET_COMPANIESBYIDBYCOMPANY_SUCCESS";
export const GET_PROFILEBYCOMPANY_BEGIN = "GET_PROFILEBYCOMPANY_BEGIN";
export const GET_PROFILEBYCOMPANY_SUCCESS = "GET_PROFILEBYCOMPANY_SUCCESS";
export const GET_PROFILEBYCOMPANY_ERROR = "GET_PROFILEBYCOMPANY_ERROR";
export const UPDATE_PROFILEBYCOMPANY_BEGIN = "UPDATE_PROFILEBYCOMPANY_BEGIN";
export const UPDATE_PROFILEBYCOMPANY_SUCCESS = "UPDATE_PROFILEBYCOMPANY_SUCCESS";
export const UPDATE_PROFILEBYCOMPANY_ERROR = "UPDATE_PROFILEBYCOMPANY_ERROR";
export const GET_JOBSBYCOMPANY_BEGIN = "GET_JOBSBYCOMPANY_BEGIN";
export const GET_JOBSBYCOMPANY_SUCCESS = "GET_JOBSBYCOMPANY_SUCCESS";
export const GET_JOBSBYIDBYCOMPANY_BEGIN = "GET_JOBSBYIDBYCOMPANY_BEGIN";
export const GET_JOBSBYIDBYCOMPANY_SUCCESS = "GET_JOBSBYIDBYCOMPANY_SUCCESS";

export const GET_STUDENTSBYIDBYCOMPANY_BEGIN = "GET_STUDENTSBYIDBYCOMPANY_BEGIN";
export const GET_STUDENTSBYIDBYCOMPANY_SUCCESS = "GET_STUDENTSBYIDBYCOMPANY_SUCCESS";

export const CREATE_JOBDRIVEBYCOMPANY_BEGIN = "CREATE_JOBDRIVEBYCOMPANY_BEGIN";
export const CREATE_JOBDRIVEBYCOMPANY_SUCCESS = "CREATE_JOBDRIVEBYCOMPANY_SUCCESS";
export const CREATE_JOBDRIVEBYCOMPANY_ERROR = "CREATE_JOBDRIVEBYCOMPANY_ERROR";


export const UPDATE_JOBDRIVEBYCOMPANY_BEGIN = "UPDATE_JOBDRIVEBYCOMPANY_BEGIN";
export const UPDATE_JOBDRIVEBYCOMPANY_SUCCESS = "UPDATE_JOBDRIVEBYCOMPANY_SUCCESS";
export const UPDATE_JOBDRIVEBYCOMPANY_ERROR = "UPDATE_JOBDRIVEBYCOMPANY_ERROR";
export const DELETE_JOBDRIVEBYCOMPANY_BEGIN = "DELETE_JOBDRIVEBYCOMPANY_BEGIN";
export const DELETE_JOBDRIVEBYCOMPANY_SUCCESS = "DELETE_JOBDRIVEBYCOMPANY_SUCCESS";
export const DELETE_JOBDRIVEBYCOMPANY_ERROR = "DELETE_JOBDRIVEBYCOMPANY_ERROR";
export const ACTIONON_JOBDRIVEBYCOMPANY_BEGIN = "ACTIONON_JOBDRIVEBYCOMPANY_BEGIN";
export const ACTIONON_JOBDRIVEBYCOMPANY_SUCCESS = "ACTIONON_JOBDRIVEBYCOMPANY_SUCCESS";
export const ACTIONON_JOBDRIVEBYCOMPANY_ERROR = "ACTIONON_JOBDRIVEBYCOMPANY_ERROR";


export const GET_STATSBYCOMPANY_BEGIN = "GET_STATSBYCOMPANY_BEGIN";
export const GET_STATSBYCOMPANY_SUCCESS = "GET_STATSBYCOMPANY_SUCCESS";