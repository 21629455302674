import React, { useEffect, useState } from "react";
import { fetchLists } from "../api/services";
import { useAppContext } from "../context/appContext";
import { axiosClient } from "../api/config";

function AppliedJobOrRequest() {
  const [jobs, setJObs] = useState([]);
  const { user: logged } = useAppContext();

  const user = JSON.parse(logged);

  const updateState = ({ data }) => {
    setJObs(data.jobs);
  };

  useEffect(() => {
    if (user) {
      const payload =
        user.role === "student" ? { student_id: user.id } : { dei_id: user.id };
      fetchLists(payload, "/applied_jobs", updateState);
    }
  }, []);

  const changeJob = async ({ id, status, job, member }) => {
    console.log(id, status, job, member);
    await axiosClient.post("/change", { id, status });
    alert(`${job} request from ${member} has ${status}`);
    window.location.reload()
  };

  return (
    <div className="flex flex-col w-full">
      <div className="overflow-x-auto ">
        <div className="align-middle inline-block min-w-full ">
          <div className="shadow overflow-hidden border-b border-gray-200 rounded-lg">
            {user?.role === "dei" ? (
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Student
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Location
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Applied Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Job Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Applied Id
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {jobs?.map((item, index) => (
                    <tr key={index}>
                      <td className="px-6 py-1 whitespace-nowrap ">
                        <div className="text-sm text-gray-500">
                          {item?.student.email}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500">
                          {item?.student.location}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500">
                          {new Date(item?.created).toDateString()}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500">
                          {item?.job.job_name}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500">
                          {item?.id}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <a
                          className="text-sm text-blue-500 cursor-pointer"
                          onClick={() =>
                            changeJob({
                              id: item?.id,
                              member: item?.student.email,
                              job: item?.job.job_name,
                              status: "approved",
                            })
                          }
                          target="__blank"
                        >
                          Approve
                        </a>
                        <a
                          className="text-sm text-red-500 ml-2 cursor-pointer"
                          onClick={() =>
                            changeJob({
                              id: item?.id,
                              member: item?.student.email,
                              job: item?.job.job_name,
                              status: "rejected",
                            })
                          }
                          target="__blank"
                        >
                          Reject
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Job Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Language
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Required Experience
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Applied Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {jobs?.map((item, index) => (
                    <tr key={index}>
                      <td className="px-6 py-1 whitespace-nowrap ">
                        <div className="text-sm text-gray-500">
                          {item?.job.job_name}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500">
                          {item?.job.language}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500">
                          {item?.job.required_experience} year
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500">
                          {new Date(item?.created).toDateString()}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500">
                          {item?.status}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppliedJobOrRequest;
