import React, { useState } from "react";
import { useAppContext } from "../../context/appContext";
import { createJob } from "../../api/services";

function PostJob() {
  const { user: logged } = useAppContext();

  const user = JSON.parse(logged);

  const state = {
    name: "",
    desc: "",
    lan: "",
    exp: "",
    poster: user.id,
  };

  const [values, setValues] = useState(state);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  async function onSubmit(e) {
    e.preventDefault();
    console.log(values);
    createJob(values);
  }

  return (
    <form
      className=" w-[40%] m-auto flex flex-col justify-center overflow-x-auto  border rounded-lg md:p-4 p-2"
      onSubmit={onSubmit}
    >
      <div className="w-full">
        <label
          className="text-left text-black text-md font-medium"
          htmlFor="jobDriveDesignations"
        >
          Job Name
        </label>
        <input
          className="mt-2 mb-2 w-full h-10 border border-gray-400 rounded-md
  transition duration-150 ease-in-out focus:text-gray-700
  focus:bg-white focus:border-slate-600 p-2"
          type="text"
          id="name"
          name="name"
          value={values.name}
          onChange={handleChange}
          required
        />
      </div>
      <div className="w-full">
        <label
          className="text-left text-black text-md font-medium"
          htmlFor="jobDriveLocations"
        >
          Description
        </label>
        <textarea
          className="mt-2 mb-2 w-full  border border-gray-400 rounded-md
  transition duration-150 ease-in-out focus:text-gray-700
  focus:bg-white focus:border-slate-600 p-2"
          type="text"
          name="desc"
          value={values.desc}
          onChange={handleChange}
          rows={5}
          required
        />
      </div>
      <div className="w-full">
        <label
          className="text-left text-black text-md font-medium"
          htmlFor="jobDriveLocations"
        >
          Language
        </label>
        <input
          className="mt-2 mb-2 w-full h-10 border border-gray-400 rounded-md
  transition duration-150 ease-in-out focus:text-gray-700
  focus:bg-white focus:border-slate-600 p-2"
          type="text"
          name="lan"
          value={values.lan}
          onChange={handleChange}
          required
        />
      </div>
      <div className="w-full">
        <label
          className="text-left text-black text-md font-medium"
          htmlFor="jobDriveLocations"
        >
          Required Experience
        </label>
        <input
          className="mt-2 mb-2 w-full h-10 border border-gray-400 rounded-md
  transition duration-150 ease-in-out focus:text-gray-700
  focus:bg-white focus:border-slate-600 p-2"
          type="number"
          name="exp"
          value={values.exp}
          onChange={handleChange}
          required
        />
      </div>

      <button
        className="flex mt-2 mb-2 w-full items-center justify-center space-x-2 bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-md"
        type="submit"
      >
        Create Job
      </button>
    </form>
  );
}

export default PostJob;
