import axios from "axios";
import { axiosClient, axiosClientBlog } from "./config";

const addUserToLocalStorage = ({ user, token }) => {
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("token", token);
};

const removeUserFromLocalStorage = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
};

const redirectHome = (role) => {
  if (role === "admin") {
    window.location.href = "/admin";
  } else if (role === "dei") {
    window.location.href = "/dei";
  } else if (role === "student") {
    window.location.href = "/student";
  }
};

export const logoutUser = () => {
  removeUserFromLocalStorage();
};

export const loginUser = async (currentUser) => {
  try {
    const {
      data: { info },
    } = await axiosClient.post("/login", currentUser);
    addUserToLocalStorage({ user: JSON.stringify(info), token: info.id });
    console.log("====================================");
    console.log(info);
    console.log("====================================");
    redirectHome(info.role);
  } catch (error) {
    alert(error.response?.data?.error ?? "Something went wrong login!,");
  }
};

export const registerUser = async (currentUser) => {
  try {
    await axiosClient.post("/register", currentUser);
    alert("You have created a account.");
    window.location.href = "/login";
  } catch (error) {
    alert(error.response?.data?.error ?? "Something went wrong register!,");
  }
};
export const ForgetPwd = async (ForgetPwd) => {
  try {
    await axiosClient.post("/ForgetPwd", ForgetPwd);
    alert("Pwd change succesfully.");
    window.location.href = "/login";
  } catch (error) {
    alert(error.response?.data?.error ?? "Something went wrong register!,");
  }
};

export const createJob = async (job) => {
  try {
    await axiosClient.post("/job", job);
    alert("You have created a Job.");
    window.location.reload();
  } catch (error) {
    alert(error.response?.data?.error ?? "Something went wrong creating job!,");
  }
};

export const fetchLists = async (payload, url, updateState) => {
  try {
    const data = await axiosClient.post(url, payload);
    updateState(data);
  } catch (error) {
    alert(error.response?.data?.error ?? "Something went wrong lists!,");
  }
};

export const applyJob = async (job) => {
  try {
    await axiosClient.post("/apply_job", job);
    alert(`You Applied ${job.name}.`);
    window.location.reload();
  } catch (error) {
    alert(error.response?.data?.error ?? "Something went wrong creating job!,");
  }
};


export const createNewRoom = async (room) => {
  try {
    await axiosClient.post("/add_chat_room", room);
    alert("You have created a chat room.");
    window.location.href = "/chat_room";
  } catch (error) {
    alert(error.response?.data?.error ?? "Something went wrong creating chat room!,");
  }
};


export const getBlogPosts = async () => {
  const blogs = await axiosClientBlog.get("/posts", {
    headers: {
      Authorization: "",
    },
  });

  const processed = blogs?.data?.map(async (blog) => {
    const allData = { ...blog };
    const ima = allData._links?.["wp:featuredmedia"];
    if (ima) {
      const path = ima[0].href;

      const image = await axios({
        baseURL: path,
        method: "GET",
        headers: {
          'Authorization': '',
      },
      });

      if (image) {
        allData["image"] = image.data.guid.rendered;
      }
    }

    return allData;
  });

  const finalBlogs = await Promise.all(processed);

  return finalBlogs;
};