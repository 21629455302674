import React, { useEffect, useState } from "react";
import { useAppContext } from "../context/appContext";
import { fetchLists } from "../api/services";

function ChatRoom() {
  const [rooms, setRooms] = useState([]);
  const { user: logged } = useAppContext();

  const user = JSON.parse(logged);

  const updateState = ({ data }) => {
    setRooms(data.rooms);
  };

  useEffect(() => {
    if (user) {
      const payload =
        user.role === "student" ? { student_id: user.id } : { dei_id: user.id };
      fetchLists(payload, "/get_rooms", updateState);
    }
  }, []);
  return (
    <div className="flex flex-col w-full">
      <div className="overflow-x-auto ">
        <div className="align-middle inline-block min-w-full ">
          <div className="shadow overflow-hidden border-b border-gray-200 rounded-lg">
            <a
              href="/create_room"
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 mb-4 px-4 rounded"
            >
              Create Room
            </a>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Room Id
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {user?.role === "dei" ? "Student" : "Dei"}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Location
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {rooms?.map((item, index) => (
                  <tr key={index}>
                    <td className="px-6 py-1 whitespace-nowrap ">
                      <div className="text-sm text-gray-500">
                        {item?.room_id}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {user?.role === "student"
                          ? item.dei.email
                          : item.student.email}
                      </div>
                    </td>
                    <td className="px-6 py-1 whitespace-nowrap ">
                      <div className="text-sm text-gray-500">
                        {user?.role === "student"
                          ? item.dei.location
                          : item.student.location}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        <a
                          href={`/chat/${item.room_id}`}
                          className="text-sm text-blue-500 cursor-pointer"
                        >
                          continue chat
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatRoom;
