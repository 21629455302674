import axios from "axios";

export const axiosClient = axios.create({
  baseURL: "http://127.0.0.1:8000/api",
});

export const axiosClientBlog = axios.create({
  baseURL: "http://localhost/academia/wp-json/wp/v2",
  headers: {
    Authorization: "",
  },
});