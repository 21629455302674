import React from "react";
import { useLocation } from "react-router-dom";

function SinglePost() {
  const location = useLocation();

  const state = location.state;
  const { title, image,content } = state;

  console.log(state);
  return (
    <div class="max-w-screen-lg mx-auto">
      <main class="mt-10">
        <div class="mb-4 md:mb-0 w-full mx-auto relative">
          <div class="px-4 lg:px-0">
            <h2 class="text-4xl mb-10 mt-10 font-semibold text-gray-800 leading-tight">
              {title.rendered}
            </h2>
          </div>

          <img
            src={
              image ??
              "https://images.unsplash.com/photo-1515378960530-7c0da6231fb1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
            }
            class="w-full object-cover lg:rounded"
            style={{ height: "28em" }}
          />
        </div>

        <div class="flex flex-col lg:flex-row lg:space-x-12">
          <div class="px-4  lg:px-0 mt-12 text-gray-700 text-lg leading-relaxed w-full lg:w-3/4">
            <div className="grid grid-flow-row gap-2" dangerouslySetInnerHTML={{__html: content.rendered}}></div>
          </div>

        </div>
      </main>
    </div>
  );
}

export default SinglePost;
