import React from 'react'

function AdminHome() {
  return (
    <div>
      Home
    </div>
  )
}

export default AdminHome