import React, { useEffect, useState } from "react";
import { useAppContext } from "../context/appContext";
import { createNewRoom, fetchLists } from "../api/services";

function CreateRoom() {
  const { user: logged } = useAppContext();

  const user = JSON.parse(logged);

  const state = {
    student: "",
    dei: "",
  };

  const [values, setValues] = useState(state);
  const [students, setStudents] = useState([]);
  const [deis, setDeis] = useState([]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  async function onSubmit(e) {
    e.preventDefault();
    console.log(values);
    createNewRoom(values);
  }

  useEffect(() => {
    if (user) {
      fetchLists({ role: "student" }, "/members", ({ data }) => {
        setStudents(data);
      });
      fetchLists({ role: "dei" }, "/members", ({ data }) => {
        setDeis(data);
      });
    }
  }, []);

  return (
    <form
      className=" w-[40%] m-auto flex flex-col justify-center overflow-x-auto  border rounded-lg md:p-4 p-2"
      onSubmit={onSubmit}
    >
      <div className="w-full">
        <label
          className="text-left text-black text-md font-medium"
          htmlFor="jobDriveStreams"
        >
          Select A Dei
        </label>
        <select
          className="mt-2 mb-2 w-full border border-gray-400 rounded-md
  transition duration-150 ease-in-out focus:text-gray-700
  focus:bg-white focus:border-slate-600 p-2"
          type="text"
          id="dei"
          name="dei"
          placeholder="Select Dei"
          value={values.dei}
          onChange={handleChange}
          required
        >
          <option defaultChecked>Select</option>
          {deis.map((item) => (
            <option value={item.id}>{item.email}</option>
          ))}
        </select>
      </div>

      <div className="w-full">
        <label
          className="text-left text-black text-md font-medium"
          htmlFor="jobDriveStreams"
        >
          Select A Student
        </label>
        <select
          className="mt-2 mb-2 w-full border border-gray-400 rounded-md
  transition duration-150 ease-in-out focus:text-gray-700
  focus:bg-white focus:border-slate-600 p-2"
          type="text"
          id="student"
          name="student"
          placeholder="Select Dei"
          value={values.student}
          onChange={handleChange}
          required
        >
          <option defaultChecked>Select</option>

          {students.map((item) => (
            <option value={item.id}>{item.email}</option>
          ))}
        </select>
      </div>

      <button
        className="flex mt-2 mb-2 w-full items-center justify-center space-x-2 bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-md"
        type="submit"
      >
        Create
      </button>
    </form>
  );
}

export default CreateRoom;
